@tailwind base;
@tailwind components;
@tailwind utilities;

.sm {
  @apply h-40 w-1/3;
}

.md {
  @apply h-40 w-2/5;
}

.lg {
  @apply h-40 w-1/2;
}

.start {
  @apply items-start;
}

.center {
  @apply items-center;
}

.end {
  @apply items-end;
}

.baseline {
  @apply items-baseline;
}

.stretch {
  @apply items-stretch;
}

.sl-sm {
  @apply w-32;
}

.sl-md {
  @apply w-48;
}

.sl-lg {
  @apply w-60;
}

.sl-xl {
  @apply w-72;
}

::-webkit-scrollbar {
  @apply w-1 bg-white rounded;
}
::-webkit-scrollbar-thumb {
  @apply w-1 bg-gray-200 rounded;
}

.scrollbar {
  @apply flex flex-col overflow-y-auto overflow-x-hidden lg:pr-1;
}

input:active,
input:focus {
  @apply outline-none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="text"].credentials::-webkit-input-placeholder {
  @apply text-sm font-normal;
}
input[type="number"].credentials::-webkit-input-placeholder {
  @apply text-sm font-normal;
}
/*Stepper*/

.stepItem {
  @apply w-8 h-8  2xl:w-10  2xl:h-10 rounded-full flex justify-center items-center z-10;
}

.stepItem.processing {
  @apply w-10 h-10  2xl:w-12  2xl:h-12 border-2 border-red-500 p-1 flex justify-center items-center z-10;
}

.stepItem-content {
  @apply w-full h-full rounded-full flex justify-center items-center;
}

.stepItem-content.completed {
  @apply bg-bright-green;
}

.stepItem-content.processing {
  @apply bg-light-gray;
}

.stepItem-content.waiting {
  @apply bg-light-gray;
}

.stepItem-icon {
  @apply bg-transparent text-white font-bold text-[16px]  2xl:text-[20px] tracking-wide pt-px;
}

@layer components {
  .stepItems {
    @apply flex flex-col items-center justify-start gap-3;
  }

  .stepItems-horizontal {
    @apply flex-row justify-center items-center gap-0;
  }

  .wrapper {
    @apply flex flex-col items-center justify-center gap-5 z-10;
  }

  .wrapper-horizontal {
    @apply flex-row items-center gap-0 z-10;
  }

  .wrapper:not(:last-of-type):after {
    content: "";
    @apply h-px w-[34px] bg-general rotate-90 -translate-y-[4px];
  }

  .wrapper-horizontal:not(:last-of-type):after {
    @apply w-[60px] transform-none;
  }
}

.flex-v-center {
  @apply flex flex-col justify-center items-center;
}

.flex-h-center {
  @apply flex flex-row justify-center items-center;
}

.plus-btn {
  @apply h-6 w-6 2xl:w-7 2xl:h-7 rounded-[999px] relative;
}

.plus-btn::after,
.plus-btn::before {
  @apply block bg-white absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2;
  content: "";
}
.plus-btn::after {
  @apply h-[15px] w-[2px];
}

.plus-btn::before {
  @apply h-[2px] w-[15px];
}

#Option {
  @apply h-full flex-v-center;
}

#Option > input[type="checkbox"] {
  @apply appearance-none w-5 h-5 border border-gray-500 outline-hidden relative;
  -webkit-appearance: none;
}

#Option > input[type="checkbox"]:checked::before {
  content: "";
  @apply w-[9px] h-0.5 bg-gray-500 rotate-[46deg] absolute left-0 top-[10px];
}
#Option > input[type="checkbox"]:checked::after {
  content: "";
  @apply w-[14px] h-0.5 bg-gray-500 -rotate-[46deg] absolute top-[8px] left-[5px];
}

.rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
.rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
  @apply !border-0  !rounded-none !bg-transparent !p-0 !h-8;
}

select.rdp-dropdown {
  height: 35px !important;
}

.radio-btn input[type="radio"] {
  @apply opacity-0 fixed w-0 h-0;
}

.radio-btn label {
  @apply bg-white text-general text-sm p-1 w-full h-full hover:cursor-pointer flex items-center justify-center relative;
}

.radio-btn input[type="radio"]:checked + label {
  @apply bg-bright-green text-white;
}

.custom-input {
  @apply border border-gray-500 bg-white text-gray-500 font-semibold h-8 lg:h-10 px-1 text-xs sm:text-sm flex-v-center hover:cursor-pointer;
}

.loading-spinner {
  @apply inline-block relative w-20 h-20;
}
.loading-spinner div {
  @apply absolute top-8 w-3 h-3 rounded-[50%] bg-gray-400;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-spinner div:nth-child(1) {
  @apply left-2;
  animation: loading-spinner1 0.6s infinite;
}
.loading-spinner div:nth-child(2) {
  @apply left-2;
  animation: loading-spinner2 0.6s infinite;
}
.loading-spinner div:nth-child(3) {
  @apply left-8;
  animation: loading-spinner2 0.6s infinite;
}
.loading-spinner div:nth-child(4) {
  @apply left-14;
  animation: loading-spinner3 0.6s infinite;
}
@keyframes loading-spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes loading-spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.text-grow {
  @apply text-xs sm:text-sm md:text-base;
}

.square-btn {
  @apply text-white text-xs cursor-pointer font-semibold disabled:bg-red-300;
}

.dropdown-box {
  @apply w-full bg-white border border-gray-500 h-8 md:h-10 px-2 hover:cursor-pointer flex items-center;
}
.dropdown-arrow {
  @apply w-2 h-2 md:w-[10px] md:h-[10px] absolute right-3 border-r-2 border-b-2 border-gray-500;
}
.dropdown-content {
  @apply absolute z-50 bg-white w-full border border-t-0 border-gray-500;
}
.dropdown-content > div {
  @apply p-2 cursor-pointer hover:text-white hover:bg-gray-500;
}
